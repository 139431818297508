import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/site/home"),
      },
      {
        path: "/catalog",
        name: "catalog",
        component: () => import("../views/site/Katalog"),
      },
      {
        path: "/exclusive-products",
        name: "ExclusiveProducts",
        component: () => import("../views/site/ExclusiveProducts"),
      },
      {
        path: "/brand-products",
        name: "BrandProducts",
        component: () => import("../views/site/BrandProducts"),
      },
      {
        path: "/tourism",
        name: "Tourism",
        component: () => import("../views/site/Tourism"),
      },
      {
        path: "/contact",
        name: "Contact",
        component: () => import("../views/site/Contact"),
      },
      {
        path: "/services",
        name: "Services",
        component: () => import("../views/site/Services"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/site/News/news"),
      },
      {
        path: "/news/:news_id",
        name: "newsId",
        component: () => import("../views/site/News/newsId"),
      },
      {
        path: "/brand-map",
        name: "BrandMap",
        component: () => import("../views/site/BrandMap"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/site/Order"),
      },
      {
        path: "/detailed-page/:product_id",
        name: "detailed-page",
        component: () => import("../views/site/DetailedPage/detailed-page"),
      },
      {
        path: `/catalog/:katalog_name`,
        name: "detailed-catalog",
        component: () =>
          import("../views/site/DetailedCatalog/detailed-catalog.vue"),
      },
      {
        path: "/my-favourites",
        name: "favourites",
        component: () => import("../views/cabinet/my-favourites"),
      },
      {
        path: "/my-cart",
        name: "my-cart",
        component: () => import("../views/cabinet/my-cart"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/site/Order"),
      },
    ],
  },
  {
    path: "/cabinet",
    component: () => import("../layouts/Cabinet"),
    children: [
      {
        path: "/cabinet",
        name: "cabinet",
        component: () =>
          import("../views/cabinet/my-account/general-information"),
      },
      {
        path: "/add-product",
        name: "add-product",
        component: () => import("../views/cabinet/my-account/add-product"),
      },
      {
        path: "/my-products",
        name: "my-products",
        component: () => import("../views/cabinet/my-account/my-products"),
      },
      {
        path: "/my-excProduct",
        name: "my-excProduct",
        component: () => import("../views/cabinet/my-account/my-excProduct"),
      },
    ],
  },
  {
    path: "/sign-in",
    component: () => import("../layouts/Auth"),
    children: [
      {
        path: "/sign-in",
        name: "login",
        component: () => import("../components/layouts/auth/login"),
      },
    ],
  },
  {
    path: "/sign-up",
    component: () => import("../layouts/Auth"),
    children: [
      {
        path: "/sign-up",
        name: "register",
        component: () => import("../components/layouts/auth/register"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
