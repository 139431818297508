import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppText from "./components/shared-components/AppText";
import "./assets/styles/main.scss";
import "./plugins/media/media-mixin";
import "./plugins/directives/click-outside";
import "./plugins/mixins/mixin";
import api from "./service/apiService";
import * as veeValidate from "./plugins/vee-validate/vee-validate";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import i18n from "./locales/i18n";
import Util from "@/plugins/globalMethod";
import VueMask from "v-mask";
import Moment from "vue-moment";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Paginate from "vuejs-paginate";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueSkeletonLoader from "skeleton-loader-vue";

Vue.config.productionTip = false;
Vue.prototype.$localeKey = Util.localeKey;
Vue.component("AppText", AppText);
Vue.component("app-loading", Loading);
Vue.component("app-paginate", Paginate);
Vue.use(VueAwesomeSwiper);
Vue.use(Element);
Vue.use(api);
Vue.use(Notifications, { velocity });
Vue.use(Moment);
Vue.use(VueMask);
Vue.component("vue-skeleton-loader", VueSkeletonLoader);
new Vue({
  router,
  veeValidate,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
