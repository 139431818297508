const state = {
  windowWidth: null,
  category: [],
  field: [],
  loading: false,
  product: {},
  products: [],
  users: [],
  userProducts: [],
  searchProduct: [],
};

const getters = {
  windowWidth: (state) => state.windowWidth,
  category: (state) => state.category,
  field: (state) => state.field,
  loading: (state) => state.loading,
  product: (state) => state.product,
  products: (state) => state.products,
  users: (state) => state.users,
  userProducts: (state) => state.userProducts,
  searchProduct: (state) => state.searchProduct,
};

const mutations = {
  setWindowWidth(state, newWidth) {
    state.windowWidth = newWidth;
  },
  setCategory(state, category) {
    state.category = category;
  },
  setField(state, field) {
    state.field = field;
  },
  setProduct(state, productId) {
    state.product = productId;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setUserProducts(state, product) {
    state.userProducts = product;
  },
  setSearchProducts(state, product) {
    state.searchProduct = product;
  },
  setLoading: (state, data) => (state.loading = data),
};
const actions = {
  async getCategory({ commit }) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get("category/")
        .then((data) => {
          if (!data.error && data) {
            commit("setCategory", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting Category" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getCategoryField({ commit }, payload) {
    console.log(payload);
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(
          `hunar/products/${payload.soha}/?page_number=${payload.page_number}&limit=${payload.limit}`
        )
        .then((data) => {
          if (!data.error && data) {
            commit("setField", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting CategoryName" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getProduct({ commit }, productId) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(`product/${productId}/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setProduct", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting Product" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getProducts({ commit }) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(`products/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setProducts", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting Products" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getUsers({ commit }) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(`users/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setUsers", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting Users" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getUserProducts({ commit }, payload) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(
          `products/user/get/${payload.userId}/?page_number=${payload.pageNumber}&limit=${payload.limit}`
        )
        .then((data) => {
          if (!data.error && data) {
            commit("setUserProducts", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting UserProducts" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getUserProductsNoLimit({ commit }, payload) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(`products/user/get/${payload.userId}/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setUserProducts", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting UserProducts" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getSearchProducts({ commit }, query) {
    try {
      await this._vm.$api
        .get(`search/${query}/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setSearchProducts", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting UserProducts" + ": " + error);
        })
        .finally(() => {
          // commit("setLoading", false);
        });
    } catch (e) {
      // commit("setLoading", false);
      console.log(e);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
