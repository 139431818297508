import Vue from "vue";
import { mapGetters } from "vuex";
import { baseURL } from "../../service/apiService";

Vue.mixin({
  data() {
    return {
      baseURL,
    };
  },
  computed: {
    ...mapGetters(["isLoggedOn", "cart", "favourite"]),
    userIsLoggedOn() {
      return this.isLoggedOn;
    },
  },
  methods: {
    successNotification(message) {
      this.$notify({
        group: "admin",
        title: message,
        closeOnClick: true,
        duration: 10000,
      });
    },
    errorNotification(message, title = "Hatolik yuz berdi") {
      this.$notify({
        group: "admin",
        title: title,
        text: message,
        type: "error",
        closeOnClick: true,
        duration: 10000,
      });
    },
    currencyFormat(number) {
      try {
        if (number % 1 === 0) return number;
        if (number === null || number === undefined || number === 0)
          return false;
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$& ");
      } catch {
        return number;
      }
    },
    storeRegisterTimeToStorage(testTime) {
      localStorage.removeItem("testTime");
      localStorage.setItem("testTime", testTime);
    },
    authStatusToStorageCode(code) {
      localStorage.removeItem("auth_status_code");
      localStorage.setItem("auth_status_code", code);
    },
    authStatusToStoragePhone(phone) {
      localStorage.removeItem("auth_status_phone");
      localStorage.setItem("auth_status_phone", phone);
    },
    authStatusToStorageProfile(profile) {
      localStorage.removeItem("auth_status_profile");
      localStorage.setItem("auth_status_profile", profile);
    },
    isProductInCartList(productId) {
      return !!this.cart?.find(
        (product) => parseInt(product.id) == parseInt(productId)
      );
    },
    isProductInFavouritesList(productId) {
      return !!this.favourite?.find(
        (product) => parseInt(product.id) == parseInt(productId)
      );
    },
  },
});
