const state = {
  user: {},
  accessToken: null,
  isLoggedOn: false,
  favourite: [],
  cart: [],
};

const getters = {
  user: (state) => state.user,
  accessToken: (state) => state.accessToken,
  isLoggedOn: (state) => state.isLoggedOn,
  cart: (state) => state.cart,
  favourite: (state) => state.favourite,
  cartCount: (state) => {
    return state.cart.length;
  },
  favouriteCount: (state) => {
    return state.favourite.length;
  },
  totalPrice(state) {
    let totalPrice = 0;
    state.cart.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
    return totalPrice;
  },
};
const mutations = {
  setUser: (state, data) => (state.user = data),
  setAccessToken: (state, data) => (state.accessToken = data),
  setIsLoggedOn: (state, data) => (state.isLoggedOn = data),
  setFavourite: (state, data) => (state.favourite = data),
  addToCart(state, item) {
    let productInCart = state.cart?.find((product) => {
      return product.id === item.id;
    });
    if (productInCart) {
      //
    } else {
      state.cart.push(item);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    }
  },
  updateState(state, cart) {
    state.cart = cart;
  },

  removeProduct(state, id) {
    state.cart.splice(
      state.cart?.findIndex((item) => parseInt(item.id) === parseInt(id)),
      1
    );
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  clearCart(state) {
    state.cart = [];
    localStorage.removeItem("cart");
  },
  //
  addToFavourite(state, item) {
    let productInFavourite = state.favourite?.find((product) => {
      return product.id === item.id;
    });
    if (productInFavourite) {
      //
    } else {
      state.favourite.push(item);
      localStorage.setItem("favourite", JSON.stringify(state.favourite));
    }
  },
  removeProductFavourite(state, id) {
    state.favourite?.splice(
      state.favourite?.findIndex((item) => parseInt(item.id) === parseInt(id)),
      1
    );
    localStorage.setItem("favourite", JSON.stringify(state.favourite));
  },
  updateStateFavourite(state, favourite) {
    state.favourite = favourite;
  },
  incrementProductQuantity(state, productId) {
    const product = state.cart.find((p) => p.id === productId);
    if (product) {
      product.quantity++;
    }
  },
  decrementProductQuantity(state, productId) {
    const product = state.cart.find((p) => p.id === productId);
    if (product && product.quantity > 1) {
      product.quantity--;
    }
  },
  itemCartPrice(state, product) {
    const item = state.cart.find((e) => e.id === product.id);
    if (item) {
      state.cart.price += item.quantity;
    }
  },
};
const actions = {
  async getUser({ commit }, payload) {
    try {
      commit("setLoading", true);
      await this._vm.$api
        .get(`profile/${payload}/`)
        .then((data) => {
          if (!data.error && data) {
            commit("setUser", data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          console.log("Error on getting user by token" + ": " + error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    } catch (e) {
      console.log(e);
    }
  },
  loadStateFromLocal({ commit }) {
    const localState = localStorage.getItem("cart");
    if (localState) {
      commit("updateState", JSON.parse(localState));
    }
  },
  loadFavourite({ commit }) {
    const localState = localStorage.getItem("favourite");
    if (localState) {
      commit("updateStateFavourite", JSON.parse(localState));
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
